<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Project Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>4/4</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->							
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><router-link :to="`/post-project-listing/${itemId}`">Step 1</router-link></li>
                            <li><router-link :to="`/post-project-listing-2/${itemId}`">Step 2</router-link></li>
                            <li><router-link :to="`/post-project-listing-3/${itemId}`">Step 3</router-link></li>
                            <li><router-link :to="`/post-project-listing-4/${itemId}`" class="active">Step 4</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="property-box">
                        <div class="property-inner-box">
                            <div class="comman-title">
                                <h3>Developer Information</h3>
                            </div>								
                            <div class="row row-cols-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Developer Name</label>
                                        <input type="text" class="form-control" placeholder="Developer Name" v-model="form.developer_info_name">
                                    </div>
                                </div>									 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Office & Showroom Address / Location </label>
                                        <input type="text" class="form-control" placeholder="Office & Showroom Address / Location " v-model="form.developer_info_address">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>WhatsApp Contact</label>
                                        <input type="text" class="form-control" placeholder="+65 000 232" v-model="form.developer_info_whatsApp_contact">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Office Contact</label>
                                        <input type="text" class="form-control" placeholder="Office Contact" v-model="form.developer_info_office_contact">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Email Address</label>
                                        <input type="text" class="form-control" placeholder="Yourmail@gmail.com" v-model="form.developer_info_email_address">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Official Website</label>
                                        <input type="text" class="form-control" placeholder="hhtp//www." v-model="form.developer_info_official_website">
                                    </div>
                                </div>
                            </div>								 			
                        </div>
                        <div class="property-inner-box">
                            <div class="comman-title">
                                <h3>Uploads/Media</h3>
                            </div>								
                            <div class="row row-cols-2">

                                <div class="col">
                                    <div class="form-group">
                                        <a href="javascript:;" class="comman-btn-1 openBrowsePopup" data-id="up"  >Upload Photos <img src="frontend/images/upload-icon.svg" alt=""></a>
                                        <div class="badge-list-post listofUpload_unit_photo">
                                            <span v-for="option in actual_unit_photo_list" :key="option.id" :value="option.id" 
                                            :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                            >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesProjectListing/${option.photo_name}`" target="_blank" class="text-white"> {{option.photo_original_name}} &nbsp;</a>
                                                <a class="fa fa-trash text-white  deleteUploadACtualUnitPhoto" data-deleteType="mb" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <!-- <a href="#" class="comman-btn-1">Unit Layout & Site Plan <img src="frontend/images/upload-icon.svg" alt=""></a> -->
                                        <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="ul"  >Upload Site Plan<img src="frontend/images/upload-icon.svg" alt=""></a>
                                        <span v-for="option in otherDocListUL" :key="option.id" :value="option.id" 
                                        :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                        >
                                            <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a>
                                            <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                <span class="visually-hidden">X</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="mb" >Upload Brochure
                                            <img src="frontend/images/upload-icon.svg" alt="">
                                        </a>
                                        <span
                                        v-for="option in otherDocListMB" :key="option.id" :value="option.id" 
                                        :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                        >
                                            <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a> 
                                            <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                <span class="visually-hidden">X</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col">
                                    <div class="form-group">										
                                        <!-- <button class="comman-btn-1 w-100">Add Media Link <img src="frontend/images/plus-circal.svg" alt=""></button> -->
                                        <a href="javascript:;" class="comman-btn-1 w-100 openMedialinkPopup"> Add Media Link <img src="frontend/images/plus-circal.svg" alt=""></a>
                                        <div class="badge-list-post listofMedia_link">
                                            <span v-for="option in mediaLinksList" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`media_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}">
                                                <a :href="`${option.link_url}`" target="_blank" class="text-white"> {{option.link_name}} ( {{option.link_type}} ) &nbsp;</a>
                                                <a class="fa fa-trash text-white  deleteMediaLink" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>	

                        <div class="d-flex justify-content-md-end">
                            <router-link :to="`/post-project-listing-3/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                            <!-- <button class="btn comman-btn-2 ms-3">Next</button> -->
                        </div>

                        <div class="property-inner-box mt-5">															 							
                            <div class="row row-cols-4">
                                <div class="col">
                                    <div class="form-group">										
                                        <button class="comman-btn-2 w-100"><img src="frontend/images/eye.svg" alt=""> Preview</button>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">										
                                        <button class="comman-btn-2 w-100"  @click="save('1')">Save & Publish</button>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">										
                                        <button class="comman-btn-1 w-100"  @click="save('0')">Save As Draft</button>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">										
                                        <button class="comman-btn-1 w-100">Copy-Posting</button>
                                    </div>
                                </div>
                            </div>
                        </div>							
                            
                        

                        <!-- <div class="d-flex justify-content-md-end">
                            <button class="btn comman-btn-6">Previous</button>
                            <button class="btn comman-btn-2 ms-3">Submit</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- upload photos modal -->
    <div class="modal fade" id="upload-photos-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">			
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
            <div class="modal-body text-center">
                <form id="file-upload-form" class="uploader">
                    <input id="file-upload" type="file" @change="onFileChange" name="fileUploadImg" multiple accept="image/*" />
                        <input type="hidden" name="type_photos"  id="type_photos" value="1">
                        <label for="file-upload" id="file-drag">
                            <img id="file-image" src="#" alt="Preview" class="hidden">
                            <div id="start">
                                <img src="frontend/images/big-upload-icom.svg" alt="">
                                <div>
                                    <h5 class="title_photos_upload">Please Upload Your Site Plan</h5>
                                    <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PDF, SVG <br>
                                    &lt;Max Size Per File&gt; 05 - 10 PX <br>
                                    &lt;Max No. Of Files&gt; 4 </p>
                                </div>	
                                <div id="notimage" class="hidden">Please select an image</div>
                                <!-- <span id="file-upload-btn" class="btn btn-primary">Select a file</span> -->
                            </div>
                            <div id="response" class="hidden">
                                <div id="messages"></div>
                                <progress class="progress" id="file-progress" value="0">
                                    <span>0</span>%
                                </progress>
                            </div>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    </div>  
    

    <!--  Add Media linkst -->
    <div class="modal fade" id="add-media-link-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">			
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                <div class="modal-body">                           
                    
                    <div class="comman-title">
                        <h3>Add Media Link</h3>
                    </div>
                    <form @submit.prevent="submitAddMediaLink">
                        <div class="row row-cols-md-1">
                            <div class="col">
                                <div class="form-group">
                                    <label>Link type</label>
                                    <select class="form-control" v-model="form.link_type">
                                        <option value="" disabled>Link type</option>
                                        <option>OutsideLink</option>
                                        <option>Embed</option>
                                    </select>
                                    <span  class="error" v-if="errors.link_type">{{ errors.link_type }}</span>
                                </div>
                            </div>                                   
                            <div class="col">
                                <div class="form-group">
                                    <label>Link name</label>
                                    <input id="link_name" class="form-control" type="text" v-model="form.link_name" placeholder="Link name">
                                    <span  class="error" v-if="errors.link_name">{{ errors.link_name }}</span>
                                </div>
                            </div>                                   
                            <div class="col">
                                <div class="form-group">
                                    <label>Link URL</label>
                                    <input id="link_url" class="form-control" type="text" v-model="form.link_url" placeholder="Link URL">
                                    <span  class="error" v-if="errors.link_url">{{ errors.link_url }}</span>
                                </div>
                            </div>                                   
                        </div> 
                        <button class="btn comman-btn-2" type="submit">Save</button>   
                    </form>    
                </div>
            </div>
        </div>
    </div>

</template>
  
  <script>
   import { CONSTANT } from '@/constants/constants';
//   import { OPTIONS_GROUP_1 } from '@/constants/constantsProperty';
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  export default {
    name: 'PostPropertyListingStep4', 
    data() {
        return {
            CONSTANT,
            otherDocList: [],
            otherDocListMB: [],
            otherDocListUL: [],
            mediaLinksList: [],
            actual_unit_photo_list: [],

            form : {
                developer_info_name : '',
                developer_info_address : '',
                developer_info_whatsApp_contact : '',
                developer_info_office_contact : '',
                developer_info_email_address : '',
                developer_info_official_website : '',
                link_type:'',
                link_name:''
            },
            errors: {}


        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        }
    },
    created() {
        this.fetchOptions();
    },
    mounted() {
        this.setupEventListeners();
    },
    methods: {
        validateForm() {
            console.log(this.form.facilities_ids);
             this.errors = {};
            //  if (!this.form.developer_info_name) {
            //      this.errors.developer_info_name = 'Developer Name is required.';
            //  }
            //  if (!this.form.developer_info_address) {
            //      this.errors.developer_info_address = 'Office & Showroom Address / Location is required.';
            //  }
            //  if (!this.form.developer_info_whatsApp_contact) {
            //      this.errors.developer_info_whatsApp_contact = 'WhatsApp Contact is required.';
            //  }
            //  if (!this.form.developer_info_office_contact) {
            //      this.errors.developer_info_office_contact = 'Office Contact is required.';
            //  }
            //  if (!this.form.developer_info_email_address) {
            //      this.errors.developer_info_email_address = 'Email Address is required.';
            //  }
            //  if (!this.form.developer_info_official_website) {
            //      this.errors.developer_info_official_website = 'Official Website is required.';
            //  }
            return Object.keys(this.errors).length === 0;
            //return 1
        },
        
        async save(params) {
            
            const token = localStorage.getItem('userToken');
            if (this.validateForm()) {
                try {
                        
                    const response = await this.$axios.post('api/update-developer-info-and-publish-status', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                        
                        developer_info_name : this.form.developer_info_name,
                        developer_info_address : this.form.developer_info_address,
                        developer_info_whatsApp_contact : this.form.developer_info_whatsApp_contact,
                        developer_info_office_contact : this.form.developer_info_office_contact,
                        developer_info_email_address : this.form.developer_info_email_address,
                        developer_info_official_website : this.form.developer_info_official_website,
                        publish_status : params,
                    });                    
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                    this.$router.push('/my-project');         

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }

        },
        
        async submitPost12313() {
            if (this.validateForm()) {
                //var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    
                    await this.$axios.post('api/update-developer-info-and-publish-status', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                        
                        developer_info_name : this.form.developer_info_name,
                        developer_info_address : this.form.developer_info_address,
                        developer_info_whatsApp_contact : this.form.developer_info_whatsApp_contact,
                        developer_info_office_contact : this.form.developer_info_office_contact,
                        developer_info_email_address : this.form.developer_info_email_address,
                        developer_info_official_website : this.form.developer_info_official_website,
                        publish_status : this.form.publish_status,
                    })
                    .then(response => {
                        
                        toastr.success(response.data.message, 'Success', {progressBar: true, closeButton: true});
                        var project_listing_id = response.data.output.id
                        this.$router.push('/post-project-listing-4/'+project_listing_id);    
                        
                    })
                    // this.submitPostUnitLayout();
                    

                } catch (error) {
                    
                    toastr.error('Something went wrong in API',{progressBar: true, closeButton: true})

                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        }, 
        validateAddMediaLink() {            
            this.errors = {};
            if (!this.form.link_type) {
                this.errors.link_type = 'Link type  is required.';
            }
            if (!this.form.link_name) {
                this.errors.link_name = 'Link name  is required.';
            }
            if (!this.form.link_url) {
                this.errors.link_url = 'Link URL  is required.';
            }
            return Object.keys(this.errors).length === 0;
        },  
        async submitAddMediaLink() {
            
            if (this.validateAddMediaLink()) {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.post('api/add-project-listing-media-link', {
                                user_id:this.user.id,
                                api_token:token,                    
                                project_listing_id: this.itemId,
                                link_type: this.form.link_type,
                                link_name: this.form.link_name,
                                link_url: this.form.link_url
                    });
                    var res_data = response.data.output;
                    toastr.success('Media Link added', 'Success', {progressBar: true, closeButton: true});
                    setTimeout(function(){
                        window.$(".btn-close").trigger('click');
                        var html = '<span  class="badge bg-secondary rounded-pill media_addi_'+res_data.id+' position-relative featurelist-badge" value="27">\
                                        <a  href="'+res_data.link_url+'" target="_blank" class="text-white">\
                                                '+res_data.link_name+' ('+res_data.link_type+') &nbsp;</a>\
                                        <a  class="fa fa-trash text-white deleteMediaLink" id="'+res_data.id+'"></a>\
                                    </span>';
                        window.$(".listofMedia_link").append(html);
                    },500);
                } catch (error) {
                            
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async onFileChange(event) {
            var type_photos = window.$("#type_photos").val();

            if(type_photos == 'up'){

                const files = event.target.files;
                this.selectedFiles = Array.from(files);
                if (this.selectedFiles) {
                    
                    const token = localStorage.getItem('userToken');
                    const formData = new FormData();

                    // Append files to FormData
                    this.selectedFiles
                    .forEach(file => {
                        formData.append('listing_photos[]', file);
                    });
                    formData.append('dataJson', JSON.stringify({
                    user_id: this.user.id,
                    api_token: token,
                    project_listing_id: this.itemId,
                    project_listing_unit_id: 1,
                    is_features_photo: 1,
                    }));


                    try {
                        const response = await this.$axios.post('api/add-project-listing-photos', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        });
                        console.log(response.data);
                        if(response.data.status == 1){
                            var res_data = response.data.output;
                            this.actual_unit_photo_list = res_data;
                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                            setTimeout(function(){
                                window.$(".btn-close").trigger('click');
                                // var html = '<span  class="badge bg-secondary rounded-pill addi_'+res_data.id+' position-relative featurelist-badge" value="'+res_data.id+'">\
                                //                 <a  href="'+CONSTANT.IMAGE_URL+'imagesProjectListing/'+res_data.photo_name+'" target="_blank" class="text-white">\
                                //                         '+res_data.photo_original_name+' &nbsp;</a>\
                                //                 <span  class="fa fa-trash text-white deleteUploadFiles" data-deletetype="mb" id="'+res_data.id+'"></span>\
                                //             </span>';
                                // window.$(".listofUpload_unit_photo").append(html);

                            },500);
                        }else{
                            toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                        }
                    } catch (error) {                
                        console.log(error);
                        if (error.response && error.response.data) {
                            toastr.error(error.response.data.errors, 'Error', { timeOut: 3000 });
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                    this.loading = false;
                    }
                }

            }else{
                var doc_type_val = '';
                if(type_photos == 'ul'){
                    doc_type_val = 'Site Plan';
                }
                if(type_photos == 'mb'){
                    doc_type_val = 'Marketing Brochure';
                }
                if(type_photos == 'vab'){
                    doc_type_val = 'Verify Authentic Badge';
                }
                const files = event.target.files;
                this.selectedFiles = Array.from(files);
                if (this.selectedFiles) {
                    
                    const token = localStorage.getItem('userToken');
                    const formData = new FormData();

                    // Append files to FormData
                    this.selectedFiles
                    .forEach(file => {
                        formData.append('doc_other_photo[]', file);
                    });
                    formData.append('dataJson', JSON.stringify({
                    user_id: this.user.id,
                    api_token: token,
                    project_listing_id: this.itemId,
                    doc_type: doc_type_val,
                    }));


                    try {
                        const response = await this.$axios.post('api/add-project-documents-other-photos', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        });

                        if(response.data.status == 1){
                            res_data = response.data.output;
                            this.otherDocListMB = res_data.filter(doc => doc.doc_type === 'Marketing Brochure');
                            this.otherDocListAVB =  res_data.filter(doc => doc.doc_type === 'Verify Authentic Badge');
                            this.otherDocListUL =  res_data.filter(doc => doc.doc_type === 'Unit Layout & Site Plan'); 
                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                            setTimeout(function(){
                                window.$(".btn-close").trigger('click');
                            },500);
                        }else{
                            toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                        }
                    } catch (error) {                
                        if (error.response && error.response.data) {
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                    this.loading = false;
                    }
                }
            }

        },
        async deleteDocUnitPhoto(id) {
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-actual-unit-photo',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    photo_id:id,


                });
                window.$(".addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },        
        async deleteDocFiles(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-documents-other-photo',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    doc_photo_id:id,
                });
                window.$(".addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },        
        async deleteMediaLink(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-property-listing-media-link',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    medialink_id:id,
                });
                window.$(".media_addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        setupEventListeners() {
            const self = this; // Save reference to `this`
            
            window.$(document).on('click', '.deleteUploadACtualUnitPhoto', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteDocUnitPhoto(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteUploadFiles', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteDocFiles(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteMediaLink', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteMediaLink(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteVerofyDoc', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteVerofyDoc(id);
                }
                });
            });
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },
        async fetchOptions() {
            
            const token = localStorage.getItem('userToken');
            // If Edit
            if(this.itemId > 0){                    
                try {
                    const response = await this.$axios.post('api/project-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    
                    this.form.developer_info_name = this.editData.developer_info_name;
                    this.form.developer_info_address = this.editData.developer_info_address;
                    this.form.developer_info_whatsApp_contact = this.editData.developer_info_whatsApp_contact;
                    this.form.developer_info_office_contact = this.editData.developer_info_office_contact;
                    this.form.developer_info_email_address = this.editData.developer_info_email_address;
                    this.form.developer_info_official_website = this.editData.developer_info_official_website;
                    this.form.pr_master_furnishing_fitting_id = this.editData.pr_master_furnishing_fitting_id;
                    

                    this.actual_unit_photo_list =  this.editData.listing_photo;

                    this.otherDocList = this.editData.doc_other_photos;
                    this.otherDocListUL =  this.otherDocList.filter(doc => doc.doc_type === 'Site Plan');                    
                    this.otherDocListMB =  this.otherDocList.filter(doc => doc.doc_type === 'Marketing Brochure');
                    this.otherDocListAVB =  this.otherDocList.filter(doc => doc.doc_type === 'Verify Authentic Badge');
                    this.mediaLinksList = this.editData.media_links;
                    

                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
            // If Edit
        }
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  