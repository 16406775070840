<template>    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Project Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>1/4</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>                            
                            <li><a class="active" href="\post-project-listing">Step 1</a></li>
                            <li :class="isDisabledStep2"><router-link :to="`/post-project-listing-2/${itemId}`">Step 2</router-link></li>
                            <li :class="isDisabledStep3"><router-link :to="`/post-project-listing-3/${itemId}`">Step 3</router-link></li>
                            <li :class="isDisabledStep4"><router-link :to="`/post-project-listing-4/${itemId}`">Step 4</router-link></li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box">
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Key Information</h3>
                                </div>
                                <div class="form-group">
                                    <label>Listing Type</label>
                                    <select 
                                        id="group1Select" 
                                        class="form-control"
                                        v-model="form.listingType" 
                                    >
                                    <option value="" selected>Listing Type</option>
                                        <option 
                                        v-for="option in optionsGroup1" 
                                        :key="option.value" 
                                        :value="option.value"
                                        >
                                        {{ option.label }}
                                        </option>
                                    </select>
                                    <span  class="error" v-if="errors.listingType">{{ errors.listingType }}</span>
                                </div>
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" v-model="form.qualify_for_foreigner" id="qualify_for_foreigner">
                                    <label class="form-check-label w-100" for="qualify_for_foreigner">
                                        Qualify For Foreigner
                                    </label>
                                </div>	
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Project Address / Location</h3>
                                </div>
                                <div class="form-group">
                                    <label>Property/Place/Project Name</label>
                                    <input type="text" class="form-control" v-model="form.project_name" placeholder="Property/Place/Project Name">
                                    <span  class="error" v-if="errors.project_name">{{ errors.project_name }}</span>
                                </div>
                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Street</label>
                                            <input
                                            class="form-control"
                                            type="text"
                                            v-model="form.street"
                                            @input="fetchSuggestions"
                                            placeholder="Type to Street..."
                                            />
                                            <span  class="error" v-if="errors.street">{{ errors.street }}</span>
                                            <ul v-if="suggestions.length" class="sugesstion_ul">
                                                <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
                                                    {{ suggestion }}
                                                </li>
                                            </ul>
                                            <span  class="error" v-if="errors.street">{{ errors.street }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Area / HDB Estate</label>
                                            <input
                                            class="form-control"
                                            type="text"
                                            v-model="form.area_hdb_estate"
                                            @input="fetchSuggestionsArea"
                                            placeholder="Type to Area..."
                                            />
                                            <span  class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
                                            <ul v-if="suggestionsArea.length" class="sugesstion_ul">
                                                <li v-for="(suggestion, index) in suggestionsArea" :key="index" @click="selectSuggestionArea(suggestion)">
                                                    {{ suggestion }}
                                                </li>
                                            </ul>
                                            <span  class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>District</label>
                                            <input type="hidden" class="form-control"  v-model="form.district" id="district" placeholder="District name">
                                            <select id="district_id" v-model="form.district_id" class="form-control" @change="changeDistrict($event)">
                                                <option value="" disabled selected>Select District</option>
                                                <option v-for="option in optionsDistrictList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span  class="error" v-if="errors.district">{{ errors.district }}</span>
                                            <!-- <input
                                            class="form-control"
                                            type="text"
                                            v-model="form.district"
                                            @input="fetchSuggestionsDistrict"
                                            placeholder="Type to District..."
                                            />
                                            <span  class="error" v-if="errors.district">{{ errors.district }}</span>
                                            <ul v-if="suggestionsDistrict.length" class="sugesstion_ul">
                                                <li v-for="(suggestion, index) in suggestionsDistrict" :key="index" @click="selectSuggestionDistrict(suggestion)">
                                                    {{ suggestion.label }}
                                                </li>
                                            </ul>
                                            <span  class="error" v-if="errors.district">{{ errors.district }}</span> -->
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Nearest MRT</label>
                                            <input type="number" min="0" class="form-control" v-model="form.nearest_MRT_in_m"  placeholder="Nearest MRT">
                                            <span  class="error" v-if="errors.nearest_MRT_in_m">{{ errors.nearest_MRT_in_m }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Nearest Bus Station.</label>
                                            <input type="number" min="0" class="form-control" v-model="form.nearest_bus_station_in_m"  placeholder="Nearest Bus Station.">
                                            <span  class="error" v-if="errors.nearest_bus_station_in_m">{{ errors.nearest_bus_station_in_m }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Nearest School.</label>
                                            <input type="number" min="0" class="form-control" v-model="form.nearest_school_in_m"  placeholder="Nearest School.">
                                            <span  class="error" v-if="errors.nearest_school_in_m">{{ errors.nearest_school_in_m }}</span>
                                        </div>
                                    </div>
                                </div>								
                            </div>
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Basic Information</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-3">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Property Type</label>
                                            <select class="form-control" v-model="form.selectedPropertyType">
                                                <option value="" disabled>Select Property Type</option>
                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                            </select>
                                            <span  class="error" v-if="errors.selectedPropertyType">{{ errors.selectedPropertyType }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Land Tenure</label>
                                            <select id="land_tenure" v-model="form.land_tenure" class="form-control">
                                                <option value="" disabled selected>Land Tenure</option>
                                                <option v-for="option in optionsLandTenuresList" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
										<div class="form-group">
											<label>Land Title Type</label>
											<select class="form-control col" v-model="form.land_title_type">
                                                <option value="" selected> Land Title Type</option>
                                                <option value="HDB">HDB</option>
                                                <option value="Strata">Strata</option>
                                                <option value="Landed">Landed</option>
                                            </select>
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>Project Site/Land Area</label>
											<input type="text" class="form-control" v-model="form.project_site_land_area" placeholder="Project Site/Land Area">
                                            <span  class="error" v-if="errors.project_site_land_area">{{ errors.project_site_land_area }}</span>
										</div>
									</div>

                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total Blocks</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_blocks" placeholder="Total Blocks">
                                            <span  class="error" v-if="errors.total_blocks">{{ errors.total_blocks }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
										<div class="form-group">
											<label>Building Height (Levels)</label>
											<input type="text" class="form-control" v-model="form.building_height_levels" placeholder="Building Height (Levels)">
                                            <span  class="error" v-if="errors.building_height_levels">{{ errors.building_height_levels }}</span>
										</div>
									</div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total Units</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_units" placeholder="Total Units">
                                            <span  class="error" v-if="errors.total_units">{{ errors.total_units }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Total carpark Lots</label>
                                            <input type="number" min="0" class="form-control" v-model="form.total_car_parks" placeholder="Total carpark Lots">
                                            <span  class="error" v-if="errors.total_car_parks">{{ errors.total_car_parks }}</span>
                                        </div>
                                    </div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Rooms</label>
                                            <input type="number" min="0" class="form-control" v-model="form.no_of_bedrooms_from" placeholder="FR Min Room Configuration">
                                            <span  class="error" v-if="errors.no_of_bedrooms_from">{{ errors.no_of_bedrooms_from }}</span>
											<!-- <select class="form-control" v-model="form.no_of_bedrooms_from">
												<option value="">FR Min Room Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Rooms</label>
                                            <input type="number" min="1" class="form-control" v-model="form.no_of_bedrooms_to" placeholder="TO Max Room Configuration">
                                            <span  class="error" v-if="errors.no_of_bedrooms_to">{{ errors.no_of_bedrooms_to }}</span>
											<!-- <select class="form-control" v-model="form.no_of_bedrooms_to">
												<option>TO Max Room Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Baths</label>
                                            <input type="number" min="1" class="form-control" v-model="form.no_of_baths_from" placeholder="FR Min Baths Configuration">
                                            <span  class="error" v-if="errors.no_of_baths_from">{{ errors.no_of_baths_from }}</span>
											<!-- <select class="form-control" v-model="form.no_of_baths_from">
												<option>FR Min Baths Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                    <div class="col">
										<div class="form-group">
											<label>No. Of Baths</label>
                                            <input type="number" min="1" class="form-control" v-model="form.no_of_baths_to" placeholder="TO Max Baths Configuration">
                                            <span  class="error" v-if="errors.no_of_baths_to">{{ errors.no_of_baths_to }}</span>
											<!-- <select class="form-control" v-model="form.no_of_baths_to">
												<option>TO Max Baths Configuration</option>
												<option>1</option>
												<option>2</option>
												<option>3</option>
												<option>4</option>
											</select> -->
										</div>
									</div>
                                </div>
                                <div class="row row row-cols-1 row-cols-md-2">
									<div class="col">
										<div class="form-group">
											<label>CarPark Lots</label>
                                            <input type="number" min="0" class="form-control" v-model="form.no_car_park_from" placeholder="FR Min Allocation Per Unit">
                                            <span  class="error" v-if="errors.no_car_park_from">{{ errors.no_car_park_from }}</span>
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>CarPark Lots</label>
                                            <input type="number" min="0" class="form-control" v-model="form.no_car_park_to" placeholder="TO Max Allocation Per Unit">
                                            <span  class="error" v-if="errors.no_car_park_to">{{ errors.no_car_park_to }}</span>
										</div>
									</div>
                                </div>
                                <div class="row row row-cols-3 row-cols-md-3">
									<div class="col">
										<div class="form-group">
											<label>Unit Size (SQF/SQM)</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.land_area_from" placeholder="FR Min Allocation Per Unit">
                                            <span  class="error" v-if="errors.land_area_from">{{ errors.land_area_from }}</span>                                            
										</div>
									</div>
                                
									<div class="col">
										<div class="form-group">
											<label>Unit Size (SQF/SQM)</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.land_area_to" placeholder="TO Max Allocation Per Unit"> 
                                            <span  class="error" v-if="errors.land_area_to">{{ errors.land_area_to }}</span>                                                                                       
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Unit Size (SQF/SQM)</label>
											<select class="form-control col" v-model="form.land_area_unit">
                                                <option>FR Min Floor Area/Built-Up</option>
                                                <option value="SQF">SQF</option>
                                                <option value="SQM">SQM</option>
                                            </select>
										</div>
									</div>
								</div> 								
                                <div class="row row row-cols-3 row-cols-md-3">
									<div class="col">
										<div class="form-group">
											<label>FR Min Floor Area/Built-Up</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.floor_area_from" placeholder="FR Min Floor Area/Built-Up">                                            
										</div>
									</div>
                                
									<div class="col">
										<div class="form-group">
											<label>TO Max Floor Area/Built-Up</label>
                                            <input type="number" min="0"  class="form-control col" v-model="form.floor_area_to" placeholder="TO Max Floor Area/Built-Up">                                            
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Floor Size (SQF/SQM)</label>
											<select class="form-control col" v-model="form.floor_area_unit">
                                                <option value="SQF">SQF</option>
                                                <option value="SQM">SQM</option>
                                            </select>
										</div>
									</div>
								</div> 								
                            </div>
                            <div class="property-inner-box">
								<div class="comman-title">
									<h3>Project Status</h3>
								</div>
								<div class="row row-cols-1 row-cols-md-2">
									<div class="col">
										<div class="form-group">
											<label>Project Stage</label>
											<select class="form-control" v-model="form.project_status">
												<option value="">Project Stage</option>
												<option>New Launch</option>
												<option>Under Construction</option>
												<option>Completed</option>
											</select>
                                            <span  class="error" v-if="errors.project_status">{{ errors.project_status }}</span>  
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Availability Date</label>
											<input type="date" class="form-control" placeholder="Availability Date" v-model="form.availability_date">											 
                                            <span  class="error" v-if="errors.availability_date">{{ errors.availability_date }}</span>
										</div>
									</div>
								</div>								
							</div>
                            <div class="d-flex justify-content-md-end">
                                <button class="btn comman-btn-6">Cancel</button>
                                <button class="btn comman-btn-2 ms-3" type="submit">Next</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { OPTIONS_GROUP_1 } from '@/constants/constantsProperty';
  import { CONSTANT } from '@/constants/constants';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';

  
  

  export default {
    name: 'PostPropertyListing', 
    components: {
      RecursiveDropdown
    },  
    data() {
        return {
            isDisabledStep2 :'disabled',
            isDisabledStep3 :'disabled',
            isDisabledStep4 :'disabled',
            isDisabledStep5 :'disabled',
            optionsGroup1: OPTIONS_GROUP_1,
            optionsLandTenuresList: [],
            optionsLayoutTypeList: [],
            optionsFloorLevelList: [],
            suggestions: [],
            suggestionsArea: [],
            suggestionsDistrict: [],
            optionsDistrictList: [],
            form: {
                project_name: '',
                qualify_for_foreigner: '0',
                street:'',
                district_id: '',
                listingType: '',
                land_tenure: '',
                layout_type_id: '',
                floor_level_id: '',
                land_title_type: '',
                main_view_id: 1,
                selectedPropertyType: '',
                land_area_from: '',
                land_area_to: '',
                floor_area_unit: 'SQF',
                land_area_unit: 'SQF',
                no_of_bedrooms_from: '',
                project_status: '',
            },
            options: this.transformData([

                { id: 1,depth:'', name: 'Residential', parent_id: 0 },

                    { id: 3,depth:'-', name: 'All HDB', parent_id: 1 },

                        { id: 6,depth:'--', name: 'HDB Flat', parent_id: 3 },
                        { id: 7,depth:'--', name: 'HDB Executive', parent_id: 3 },

                    { id: 4,depth:'-', name: 'All Non-Landed (Private)', parent_id: 1 },
                        { id: 8,depth:'--', name: 'Apartment', parent_id: 4 },
                        { id: 9,depth:'--', name: 'Walk-Up Apartment', parent_id: 4 },
                        { id: 10,depth:'--', name: 'Condominium', parent_id: 4 },
                        { id: 11,depth:'--', name: 'Executive Condominium', parent_id: 4 },

                    { id: 5,depth:'-', name: 'All Landed', parent_id: 1 },
                        { id: 12,depth:'--', name: 'Terrace / Link House', parent_id: 5 },
                        { id: 13,depth:'--', name: 'Town House', parent_id: 5 },
                        { id: 14,depth:'--', name: 'Cluster House', parent_id: 5 },
                        { id: 15,depth:'--', name: 'Conservative House', parent_id: 5 },
                        { id: 16,depth:'--', name: 'Semi-Detached/Zero-Lot', parent_id: 5 },
                        { id: 17,depth:'--', name: 'Detached/Bungalow', parent_id: 5 },
                        { id: 18,depth:'--', name: 'Good Class Bungalow (GCB)', parent_id: 5 },
                        { id: 19,depth:'--', name: 'Vacant Land', parent_id: 5 },

                { id: 2,depth:'', name: 'Commercial & Industrial', parent_id: 0 },

                    { id: 20,depth:'-', name: 'All Commercial', parent_id: 2 },
                        { id: 22,depth:'--', name: 'Retail', parent_id: 20 },
                        { id: 23,depth:'--', name: 'Office', parent_id: 20 },
                        { id: 24,depth:'--', name: 'Hotel / Hostel', parent_id: 20 },
                        { id: 25,depth:'--', name: 'Shopping Mall', parent_id: 20 },
                        { id: 26,depth:'--', name: 'Medical Suite', parent_id: 20 },
                        { id: 27,depth:'--', name: 'ShopHouse', parent_id: 20 },
                        { id: 28,depth:'--', name: 'HDB ShopHouse', parent_id: 20 },
                        { id: 29,depth:'--', name: 'Vacant Land', parent_id: 20 },
                    { id: 21,depth:'-', name: 'All Industrial', parent_id: 2 },
                        { id: 30,depth:'--', name: 'Office', parent_id: 21 },
                        { id: 31,depth:'--', name: 'Warehouse (B1)', parent_id: 21 },
                        { id: 32,depth:'--', name: 'Factory (B2)', parent_id: 21 },
                        { id: 33,depth:'--', name: 'Vacant Land', parent_id: 21 }
            ]),
            errors: {}
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
      hierarchicalOptions() {        
        return this.createHierarchicalOptions(this.options);
      },
      itemId() {
        return this.$route.params.id;
      }
    },
    created() {

        if(this.itemId > 0){
            this.isDisabledStep2 = '';
            this.isDisabledStep3 = '';
            this.isDisabledStep4 = '';
            this.isDisabledStep5 = '';
        }
        this.fetchOptions();
    },
    methods: {

        validateForm() {
            console.log(this.form.listingType);
            this.errors = {};
            if (!this.form.listingType ) {
                this.errors.listingType = 'Listing Type is required.';
            }
            if (!this.form.project_name ) {
                this.errors.project_name = 'Property Name is required.';
            }
            if (!this.form.street ) {
                this.errors.street = 'Street is required.';
            }
            if (!this.form.area_hdb_estate ) {
                this.errors.area_hdb_estate = 'Area / HDB Estate.';
            }
            if (!this.form.selectedPropertyType ) {
                this.errors.selectedPropertyType = 'Property type is required.';
            }
            if (!this.form.district ) {
                this.errors.district = 'District is required.';
            }
            if (!this.form.total_units ) {
                this.errors.total_units = 'Total Units is required.';
            }
            if (!this.form.total_car_parks ) {
                this.errors.total_car_parks = 'Total carpark Lots is required.';
            }
            if (!this.form.nearest_MRT_in_m ) {
                this.errors.nearest_MRT_in_m = 'Nearest MRT is required.';
            }
            if (!this.form.nearest_bus_station_in_m ) {
                this.errors.nearest_bus_station_in_m = 'Nearest Bus Station is required.';
            }
            if (!this.form.nearest_school_in_m ) {
                this.errors.nearest_school_in_m = 'Nearest Bus Station is required.';
            }
            if (!this.form.project_site_land_area ) {
                this.errors.project_site_land_area = 'Project Site/Land Area is required.';
            }
            if (!this.form.building_height_levels ) {
                this.errors.building_height_levels = 'Building Height (Levels)  is required.';
            }
            if (!this.form.total_blocks ) {
                this.errors.total_blocks = 'Total Blocks  is required.';
            }
            // if (!this.form.total_units ) {
            //     this.errors.total_units = 'Total Units is required.';
            // }
            if (!this.form.no_of_baths_to ) {
                this.errors.no_of_baths_to = 'No. Of Baths TO is required.';
            }
            if (!this.form.no_of_bedrooms_to ) {
                this.errors.no_of_bedrooms_to = 'No. Of Rooms TO  is required.';
            }
            if (!this.form.no_of_bedrooms_from ) {
                this.errors.no_of_bedrooms_from = 'No. Of Rooms FR  is required.';
            }
            if (!this.form.no_of_baths_from ) {
                this.errors.no_of_baths_from = 'No. Of Rooms  is required.';
            }
            if (!this.form.no_of_baths_from ) {
                this.errors.no_of_baths_from = 'No. Of Baths  is required.';
            }
            if (!this.form.no_car_park_from ) {
                this.errors.no_car_park_from = 'CarPark Lots  is required.';
            }
            if (!this.form.no_car_park_from ) {
                this.errors.no_car_park_from = 'CarPark Lots FR is required.';
            }
            if (!this.form.no_car_park_to ) {
                this.errors.no_car_park_to = 'CarPark Lots TO  is required.';
            }
            if (!this.form.land_area_from ) {
                this.errors.land_area_from = 'FR Unit Size is required.';
            }
            if (!this.form.land_area_to ) {
                this.errors.land_area_to = 'TO Unit Size is required.';
            }
            if (!this.form.project_status ) {
                this.errors.project_status = 'Project Stage is required.';
            }
            if (!this.form.availability_date ) {
                this.errors.availability_date = 'Availability Date is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            //this.$router.push('/post-project-listing-2/'+this.itemId); 
            if (this.validateForm()) {
                var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    var project_listing_id = 0;
                    if(this.itemId > 0){
                        project_listing_id = this.itemId;
                    }
                    const response = await this.$axios.post('api/add-update-project', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:project_listing_id,

                        country_id: CONSTANT.COUNTRY_CODE, 
                        listing_type: this.form.listingType,
                        qualify_for_foreigner:qualify_for_foreigner,
                        project_name:this.form.project_name,
                        street:this.form.street,
                        area_hdb_estate:this.form.area_hdb_estate,
                        district:this.form.district,
                        district_id:this.form.district_id,
                        nearest_MRT_in_m:this.form.nearest_MRT_in_m,
                        nearest_bus_station_in_m:this.form.nearest_bus_station_in_m,
                        nearest_school_in_m:this.form.nearest_school_in_m,
                        
                        property_type:this.form.selectedPropertyType,
                        land_tenure:this.form.land_tenure,
                        land_title_type:this.form.land_title_type,
                        project_site_land_area:this.form.project_site_land_area,
                        total_blocks:this.form.total_blocks,
                        building_height_levels:this.form.building_height_levels,
                        total_units:this.form.total_units,
                        total_car_parks:this.form.total_car_parks,

                        no_of_bedrooms_from:this.form.no_of_bedrooms_from,
                        no_of_bedrooms_to:this.form.no_of_bedrooms_to,
                        no_of_baths_from:this.form.no_of_baths_from,
                        no_of_baths_to:this.form.no_of_baths_to,

                        no_car_park_from:this.form.no_car_park_from,
                        no_car_park_to:this.form.no_car_park_to,


                        floor_area_from:'2',
                        floor_area_to:'3',
                        floor_area_unit:this.form.floor_area_unit,

                        land_area_from:this.form.land_area_from,
                        land_area_to:this.form.land_area_to,
                        land_area_unit:this.form.land_area_unit,

                        project_status:this.form.project_status,
                        availability_date:this.form.availability_date,

                    });
                    
                    //toastr.error(response.data.message);
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    // console.log('Login successful:', response.data);
                    // console.log('id of posted', response.data.output.id);
                    var property_listing_id = response.data.output.id
                    this.$router.push('/post-project-listing-2/'+property_listing_id);         

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },  

        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },

        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },
        createOptionGroup(option) {
            if (!option.children || option.children.length === 0) {
            return {
                type: 'option',
                id: option.id,
                label: option.name,
                depth: option.depth,
            };
            }
            return {
            type: 'optgroup',
            label: option.name,
            depth: option.depth,
            children: option.children.map(child => this.createOptionGroup(child))
            };
        },
        async fetchOptions() {

            const token = localStorage.getItem('userToken');

            // Get District list
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.street, 
                    parent_id: CONSTANT.COUNTRY_CODE                   
                });
                this.optionsDistrictList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Layout type list
            try {
                const response = await this.$axios.post('api/pr-layout-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLayoutTypeList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get Floor level
            try {
                const response = await this.$axios.post('api/pr-floor-levels-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFloorLevelList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            if(this.itemId > 0){
                
                try {
                    const response = await this.$axios.post('api/project-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    console.log(this.editData);
                    this.form.listingType = this.editData.listing_type;
                    this.form.qualify_for_foreigner = this.editData.qualify_for_foreigner == 1 ? true : false;
                    this.form.project_name = this.editData.project_name;
                    this.form.street = this.editData.street;
                    this.form.selectedPropertyType = this.editData.property_type;
                    this.form.area_hdb_estate = this.editData.area_hdb_estate;
                    this.form.district = this.editData.district;
                    this.form.district_id = this.editData.district_id;
                    this.form.land_tenure = this.editData.land_tenure;
                    this.form.land_title_type = this.editData.land_title_type;
                    this.form.nearest_MRT_in_m = this.editData.nearest_MRT_in_m;
                    this.form.nearest_bus_station_in_m = this.editData.nearest_bus_station_in_m;
                    this.form.nearest_school_in_m = this.editData.nearest_school_in_m;
                    
                    
                    this.form.project_site_land_area = this.editData.project_site_land_area;
                    this.form.total_blocks = this.editData.total_blocks;
                    this.form.building_height_levels = this.editData.building_height_levels;
                    this.form.total_car_parks = this.editData.total_car_parks;
                    this.form.total_units = this.editData.total_units;


                    this.form.no_of_baths_from = this.editData.no_of_baths_from;
                    this.form.no_of_baths_to = this.editData.no_of_baths_to;

                    this.form.no_of_bedrooms_from = this.editData.no_of_bedrooms_from;
                    this.form.no_of_bedrooms_to = this.editData.no_of_bedrooms_to;

                    this.form.no_car_park_from = this.editData.no_car_park_from;
                    this.form.no_car_park_to = this.editData.no_car_park_to;

                    this.form.land_area_from = this.editData.land_area_from;
                    this.form.land_area_to = this.editData.land_area_to;
                    this.form.land_area_unit = this.editData.land_area_unit;
                    
                    this.form.floor_area_from = this.editData.floor_area_from;
                    this.form.floor_area_to = this.editData.floor_area_to;
                    this.form.floor_area_unit = this.editData.floor_area_unit;

                    
                    

                    this.form.project_status = this.editData.project_status;
                    this.form.availability_date = this.editData.availability_date;
                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
        },

        async fetchSuggestions() {
            if (this.form.street.length < 2) {
                this.suggestions = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "Locality",
                    keyword : this.street, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                this.suggestions = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.street.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },        
        async fetchSuggestionsArea() {
            if (this.form.area_hdb_estate.length < 2) {
                this.suggestionsArea = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "Locality",
                    keyword : this.area_hdb_estate, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                this.suggestionsArea = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.area_hdb_estate.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },
        async fetchSuggestionsDistrict() {
            if (this.form.district.length < 2) {
                this.suggestionsDistrict = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "City",
                    keyword : this.district, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                // this.suggestionsDistrict = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.district.toLowerCase()));
                this.suggestionsDistrict = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },
        selectSuggestion(suggestion) {
            this.form.street = suggestion;
            this.suggestions = [];
        },
        selectSuggestionArea(suggestion) {
            this.form.area_hdb_estate = suggestion;
            this.suggestionsArea = [];
        },
        selectSuggestionDistrict(suggestion) {
            this.form.district = suggestion.label;
            this.form.district_id = suggestion.value;
            this.suggestionsDistrict = [];
        },
        changeDistrict(){
            
            const selectedValue = this.form.district_id; // Get the value from v-model
            const selectedOption = this.optionsDistrictList.find(option => option.value === selectedValue);
            
            if (selectedOption) {
                const selectedLabel = selectedOption.label;
                // console.log('Selected Value:', selectedValue);
                // console.log('Selected Label:', selectedLabel);
                this.form.district = selectedLabel;
                // You can now use selectedValue and selectedLabel as needed
            }
            
        },
    }
  }
  </script>
  
  <style scoped>
    .sugesstion_ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid #ddd;
        position: absolute;
        background: #fff;
    }
    .sugesstion_ul li {
        cursor: pointer;
        padding: 8px;
    }
    .sugesstion_ul li:hover {
        background-color: #f0f0f0;
    }
  </style>
  